import './company-rating.scss';

customElements.define('company-rating', class extends HTMLElement {
  constructor() {
    super();

    this._debugMode = true;
    this._translations = translations.webComponents.companyRating;

    this._shadowRoot = this.attachShadow({
      mode: 'open'
    });
    const tpl = document.querySelector('#wc-tpl-company-rating');
    if (!tpl || !tpl.content) return; // IE bugfix
    let clonedTpl = tpl.content.cloneNode(true);

    this._ratingDescriptions = {
      1: 'C',
      2: 'B',
      3: 'A',
      4: 'AA',
      5: 'AAA',
    };

    this._shadowRoot.appendChild(clonedTpl);
    this._shadowRoot.querySelector('link').addEventListener('load', e => this.setAttribute('loaded', ''));
  }

  static get observedAttributes() {
    return ['loaded'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this._inner = this._shadowRoot.querySelector('div');
    this._inner.style.visibility = 'visible';
    this._render();
  }

  connectedCallback() {}

  disconnectedCallback() {
    window.removeEventListener('click', this._onClickOutside);
    document.body.removeChild(this._detailsElement);
    this._detailsElement = null;
    this._shadowRoot = null;
    this._translations = null;
  }

  _render() {
    if(!this._amount && this._hideEmpty) return;

    this._container = this._inner.querySelector('.container');
    this._container.innerHTML = this._template;

    this._barElement = this._container.querySelector('.upper');

    this._detailsElement = document.createElement('div');
    this._detailsElement.innerHTML = this._detailsTemplate;
    this._detailsElement.classList.add('wc-company-rating-details');

    document.body.insertAdjacentElement('beforeend', this._detailsElement);

    this._addEvents();
  }

  _addEvents() {
    window.addEventListener('click', this._onClickOutside.bind(this));

    this._barElement.addEventListener('click', e => {
      this._expanded = !this._expanded;
      this._detailsElement.style.display = this._expanded ? 'block' : 'none';
      this._positionAt();
    });
  }

  _onClickOutside(e) {
    if(!this._expanded) return;
    if(this.contains(e.target) || this._detailsElement.contains(e.target)) return;
    this._expanded = false;
    this._detailsElement.style.display = 'none';
  }

  /**
   * Positions the details
   */
   _positionAt() {
    const dist = this.dist || 0;

    let parentCoords = this.getBoundingClientRect(), left, top;

    left = parseInt(parentCoords.left);
    top = parseInt(parentCoords.bottom) + dist;

    left = (left < 0) ? parseInt(parentCoords.left) : left;
    top = (top < 0) ? parseInt(parentCoords.bottom) + dist : top;

    this._detailsElement.style.left = `${left}px`;

    const bounding = this._detailsElement.getBoundingClientRect();

    if(bounding.right > window.innerWidth) {
      this._detailsElement.style.left = window.innerWidth - (bounding.width - 10) + 'px';
    }

    this._detailsElement.style.top = `${top + pageYOffset}px`;
  }

  get _rating() {
    let r =  this.getAttribute('rating');
    return r && r.match(/null|undefined/i) === null ? r.toLocaleUpperCase() : 'N/A';
  }

  get _addressId() {
    return this.getAttribute('address-id');
  }

  get _ownAddressId() {
    return this.getAttribute('own-address-id');
  }

  get _isCompanyAdmin() {
    return !!parseInt(this.getAttribute('is-company-admin'));
  }

  get _isRatingAdmin() {
    return !!parseInt(this.getAttribute('is-rating-admin'));
  }

  get _isLoggedIn() {
    return !!parseInt(this.getAttribute('is-logged-in'));
  }

  get _score() {
    return this.getAttribute('score');
  }

  get _amount() {
    let amount = this.getAttribute('amount');
    return parseInt(amount) || 0;
  }

  get _criteria() {
    return JSON.parse(decodeURIComponent(escape(window.atob(this.getAttribute('criteria')))));
  }

  get _hasAccess() {
    return !!parseInt(this.getAttribute('has-access'));
  }

  get _expanded() {
    return this.hasAttribute('expanded');
  }

  set _expanded(bool) {
    bool ? this.setAttribute('expanded', '') : this.removeAttribute('expanded');
  }

  get _hideEmpty() {
    return !!parseInt(this.getAttribute('hide-empty'));
  }

  /**
   * check wheather the current user can rate this company
   * user must either be company admin or rating admin
   * he cannot rate is own company
   * must have access this feature
   * @returns {boolean} - user can rate this company or not
   */
  get _canRate() {
    return (this._isCompanyAdmin || this._isRatingAdmin)
           && (this._addressId != this._ownAddressId)
           && this._isLoggedIn
           && this._hasAccess;
  }

  get _template() {
    return `
      <div class="upper">
        <div class="wrap">
          <span>${this._rating || 'N/A'}</span>
        </div>
        <div class="bar">
          <div>
            <span>${this._amount}</span>
            <span>${this._amount == 1 ? this._translations.review : this._translations.reviews}</span>
          </div>
        </div>
      </div>
    `;
  }

  get _detailsTemplate() {
    return /*html*/ `
      ${this._amount > 0 ? `
        <div class="outer-ratings">
          <div class="ratings">
            <div>
              <div>&Oslash;</div>
              <div>${this._translations.criteria}</div>
            </div>

            ${this._criteria.map((criteria, i) => {
              return `
                <div>
                  <div>${criteria.rating_description}</div>
                  <div>${criteria.rating_category_name} (${criteria.rating_amount})</div>
                </div>
              `
            }).join('')}
          </div>
        </div>
      ` : this._translations.companyNotReviewed}

      <ul>
        ${this._isLoggedIn ? `
          <li>
            <a class="button tiny" href="/mundus-agri/rating">
              ${this._translations.myReviews}
            </a>
          </li>
        ` : ``}

        ${this._canRate ? `
          <li>
            <a class="button tiny" href="/mundus-agri/rating/companyRating/${this._addressId}">
              ${this._translations.reviewCompany}
            </a>
          </li>
        ` : ``}
      </ul>
    `;
  }
});